import { render, staticRenderFns } from "./versionA.vue?vue&type=template&id=3d3d2075&scoped=true&"
import script from "./versionA.vue?vue&type=script&lang=js&"
export * from "./versionA.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3d2075",
  null
  
)

export default component.exports
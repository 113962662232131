<template>
  <div>
    <!-- <PageTopBanner :data="careersTopBanner" /> -->
    <div class="section__common__container">
      <JobBox />
    </div>
    <!-- <Guide /> -->
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
import JobBox from './components/JobBox.vue';
// import Guide from './components/Guide.vue';

export default {
  name: 'Games',
  components: {
    // PageTopBanner,
    JobBox,
    // Guide
  },
  data() {
    return {
      careersTopBanner: {
        bg: require('@/assets/careers/careersPage__bannerBg.png'),
        lottie: require('@/assets/lottie/careerTopBanner.json'),
        lottieMobile: require('@/assets/lottie/careerTopBanner_m.json'),
      }
    };
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div>
    <!-- <The100TopBanner :data="99" /> -->
    <!-- <Page-top-banner :data="careersTopBanner" /> -->
    <!-- <PageTopBanner :data="careersTopBanner" /> -->
    <!-- <VersionA v-if="isOdd" /> -->
    <!-- <VersionB v-if="isEven" /> -->
    <VersionA />
    <Guide />
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
// import JobBox from './components/JobBox.vue';
import Guide from './components/Guide.vue';
import VersionA from './versionA.vue';
// import VersionB from './versionB.vue';
import { getUserRandomNo } from '@/api/job';
// import The100TopBanner from '../../layout/components/The100TopBanner.vue';
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
// import History from '../about/components/history.vue';

export default {
  name: 'Careers',
  components: {
    // PageTopBanner,
    // JobBox,
    Guide,
    VersionA,
    // VersionB,
    // The100TopBanner,
    // PageTopBanner,
    // History
  },
  data() {
    return {
      careersTopBanner: {
        title: 'Careers',
      },
      isOdd: false,
      isEven: false,
    };
  },
  created(){
    window.fbq('track', 'cookapps_careers_view');

    if(!localStorage.getItem('userRandomNo')){
      this.randomUserNo();
    }else{
      const randomNo = localStorage.getItem('userRandomNo');
      randomNo%2 === 0 ? this.isOdd = true : this.isEven = true;
    }
  },
  methods: {
    async randomUserNo() {
      const userData = {
        code: 'A/B TEST',
        value: '0'
      };
      const result = await getUserRandomNo(userData);
      result.data.id%2 === 0 ? this.isOdd = true : this.isEven = true;
      localStorage.setItem('userRandomNo', result.data.id);
    },
  }
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <section class="jobSection">
    <div class="relative">
      <div
        v-if="$screen.breakpoint === 'pc'"
        class="flex"
      >
        <div class="section__title bg-white text-left pr-4">
          <b>{{ openedJobCnt }}</b>
        </div>
        <div class="section__title dark bg-white pr-5 text-left">
          POSITIONS OPENED
        </div>
      </div>
      <div
        v-if="$screen.breakpoint !== 'pc'"
        class="flex"
      >
        <!-- <div class="section__title bg-white text-left pr-4" style="padding-bottom: 0;">
          [ <b>{{ openedJobCnt }}</b> POSITIONS ]
          <span style="padding-bottom: 0;">[ <b>{{ openedJobCnt }}</b> POSITIONS ]</span>
        </div>
        <div class="section__title dark bg-white pr-5 text-left" style="padding-top: 0;">
          OPENED
          <span style="padding-top: 0;">OPENED</span>
        </div> -->
        <div class="section__title bg-white text-left fontFamilyBlackHan font-normal">
          진행 중
        </div>
        <div class="section__title dark pr-5 text-left fontFamilyBlackHan font-normal">
          인 채용
          <span class="fontFamilyBlackHan font-normal">인 채용</span>
        </div>
      </div>
      <div
        v-if="$screen.breakpoint === 'pc'"
        class="myResume absolute"
      >
        <router-link
          to="/recruit/sign-up"
          class="myResume__link"
          @click="track"
        >
          <!-- <span>MY RESUME</span> -->
          <span>지원서 작성하기</span>
          <span class="myResume__arrow">
            <i class="xi-angle-right-min" />
          </span>
        </router-link>
      </div>
    </div>
    <div class="jobBox__container">
      <div
        v-for="item in filteredJobArr"
        :key="item.idx"
        class="jobBox"
        @click="goDetail(item)"
      >
        <div
          v-if="item.type"
          class="jobBox__wrapper"
        >
          <img
            class="jobBox__bg"
            :src="item.img"
            alt=""
          >
          <div
            v-if="$screen.breakpoint !== 'mobile'"
            class="jobBox__title"
          >
            {{ item.type }}
          </div>
          <div
            v-if="$screen.breakpoint === 'mobile'"
            class="jobBox__titleCnt fontFamilyBlackHan"
          >
            <span class="inline-block">{{ item.type }}</span>
            <span class="jobBox__cnt fontFamilyEN inline-block">{{ item.jobCnt }}</span>
          </div>
          <div
            v-if="$screen.breakpoint !== 'mobile'"
            class="jobBox__btnBox"
          >
            <button
              class="customBtn md"
              @click="goDetail(item)"
            >
              <span>See more</span>
            </button>
          </div>
          <!-- <div
            v-else
            class="jobBox__arrowRight"
          >
            <div class="swiper-button-next" />
          </div> -->
        </div>
        <skeleton-box
          v-else
          style="height: 0; padding-bottom: 56.25%; width: 110%;"
        />
      </div>
    </div>
    <div class="mt-12 text-center pb-6">
      <button
        class="customBtn lg"
        @click="$store.dispatch('app/setJobTypeId',0);$router.push(`/careers/list`);"
      >
        <span
          class="fontFamilyNoto"
          style="font-size: 1.15rem;"
        >채용 전체보기</span>
      </button>
    </div>
  </section>
</template>

<script>
import { getJobs, getOpenJobs } from '@/api/job';
import SkeletonBox from '../../../layout/components/SkeletonBox.vue';

export default {
  name: 'JobBox',
  components: {
    SkeletonBox,
  },
  data() {
    return {
      openedJobCnt: 0,
      jobArr: [
        {
          idx: 0,
          type: '게임기획 & 시나리오',
          img: require('@/assets/careers/careers_planning.png'),
          jobTypeID: 1
        },
        {
          idx: 1,
          type: '아트',
          img: require('@/assets/careers/careers_art.png'),
          jobTypeID: 4
        },
        {
          idx: 2,
          type: '프로그래밍',
          img: require('@/assets/careers/careers_programming.png'),
          jobTypeID: 6
        },
        {
          idx: 3,
          type: '사업/마케팅',
          img: require('@/assets/careers/careers_marketing.png'),
          jobTypeID: 3
        },
        {
          idx: 4,
          type: '고객관리',
          img: require('@/assets/careers/careers_crm.png'),
          jobTypeID: 11
        },
        {
          idx: 5,
          type: '인사 & 조직문화',
          img: require('@/assets/careers/careers_ppl.png'),
          jobTypeID: 12
        },
        {
          idx: 6,
          type: '비디오 & 미디어',
          img: require('@/assets/careers/careers_video.png'),
          jobTypeID: 13
        },
        {
          idx: 7,
          type: '데이터 분석',
          img: require('@/assets/careers/careers_data.png'),
          jobTypeID: 2
        },
        {
          idx: 8,
          type: '전문연구요원',
          img: require('@/assets/careers/careers_research.png'),
          jobTypeID: 7
        },
        {
          idx: 9,
          type: '프리랜서',
          img: require('@/assets/careers/careers_freelancer.png'),
          jobTypeID: 14
        },
        {
          idx: 10,
          type: '관계사',
          img: require('@/assets/careers/careers_company.png'),
          jobTypeID: 15
        },
        {
          idx: 11,
          type: '인턴',
          img: require('@/assets/careers/careers_intern.png'),
          jobTypeID: 16
        },
      ],
      filteredJobArr: [{},{},{},{},{},{}],
    };
  },
  async created(){
    this.filterArr();
    const result = await getOpenJobs();
    this.openedJobCnt = result.data.items.length;
  },
  methods: {
    track(){
      window.fbq('track', 'cookapps_careers_apply_click');
    },
    async filterArr() {
      const result = await getJobs();
      const data = result.data.items;
      let openedJobTypeList = [];
      data.map(item => {
        openedJobTypeList.push(item.id);
      });
      const filteredArr = this.jobArr.filter(item => openedJobTypeList.indexOf(item.jobTypeID) > -1);
      filteredArr.map(item => {
        item.jobCnt = data.find(elm => elm.id === item.jobTypeID).job.length;
      });
      this.filteredJobArr = filteredArr;
    },
    goDetail(item){
      dataLayer.push({
        event: 'clickVersionA',
      });

      dataLayer.push({
        event: 'clickJobType',
        jobType: item.type,
      });

      this.$store.dispatch('app/setJobTypeId', item.jobTypeID);
      this.$router.push('/careers/list');
    }
  }
};
</script>

<style lang="scss" scoped>
.jobSection{
    padding-bottom: 104px;
}
.jobBox{
    overflow: hidden;
    border-radius: 1rem;
    display: inline-block;
    vertical-align: top;
    width: calc( ( 100% - 2rem ) /3 );
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:nth-child(3n){
        margin-right: 0;
    }
    &__wrapper{
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        border-radius: 1rem;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        &::after{
            transition: all .7s cubic-bezier(1,-.1,.54,1.3);
            content: "";
            display: block;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            background-color: rgba(0, 0, 0, 0.35);
        }
    }
    &__bg{
        transition: all .7s cubic-bezier(1,-.1,.54,1.3);
        position: absolute;
        z-index: 0;
        object-fit: cover;
        width: 100%; height: 100%;
        top: 0;
        left: 0;
        user-select: none;
    }
    &__title{
        transition: all .7s cubic-bezier(1,-.1,.54,1.3);
        position: absolute;
        z-index: 1;
        bottom: 0;
        color: #FFF;
        padding: 3.2% 5%;
        text-shadow: rem(4) rem(4) rem(6) rgba($color: #000000, $alpha: .7);
        font-weight: 900;
        font-size: rem(74);
        line-height: 1.28;
        letter-spacing: -0.3px;
        line-height: 1.4;
    }
    &__btnBox{
        transition: all .7s cubic-bezier(1,-.1,.54,1.3);
        transition-delay: .1s;
        position: absolute;
        z-index: 1;
        bottom: -100%;
        padding: 6% 5.8%;
    }
}
:is(.pc, .tablet){
    .jobBox{
        &:hover{
            cursor: pointer;
            .jobBox__wrapper &::after{
                background-color: rgba($color: #000000, $alpha: .5);
            }
            .jobBox__title{
                bottom: 68%;
            }
            .jobBox__btnBox{
                bottom: 0;
            }
            .jobBox__bg{
                transform: scale(1.1);
            }
        }
    }
}
:is(.mobile, .tablet){
    .jobBox{
        overflow: hidden;
        border-radius: .7rem;
        display: inline-block;
        width: calc( ( 100% - .5rem ) /2 );
        margin-right: .5rem;
        margin-bottom: .5rem;
        &:nth-child(3n){
            margin-right: .5rem;
        }
        &:nth-child(2n){
            margin-right: 0 !important;
        }
        &__wrapper{
            padding-bottom: 62%;
        }
        &__title{
            padding: 3.2% 5%;
            line-height: 1.28;
            letter-spacing: -0.3px;
            font-size: 36px;
        }
    }
}
:is(.mobile){
  .jobBox{
    &__title{
        font-size: 20px;
    }
    &__arrowRight{
      position: absolute;
      right: 0;
      bottom: 0;
      width: rem(34);
      height: rem(34);
      z-index: 999;
      .swiper-button-next{
        width: rem(34) !important;
        height: rem(34) !important;
        right: .3rem !important;
        top: .9rem !important;
        background-color: $primaryColor !important;
      }
      .swiper-button-next::after{
        font-size: .5rem !important;
        top: 0px !important; left: 1px;
        color: #FFF !important;
      }
    }
  }
  .jobBox__wrapper::after{
    background-color: rgba($color: #000000, $alpha: .55);
  }
  .jobBox__titleCnt{
    text-align: center;
    max-width: 95%;
    padding-top: 28%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    color: #FFF;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    font-weight: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    & > div{
      height: 100%;
    }
  }
  .jobBox__cnt{
    position: relative;
    bottom: 4px;
    margin-left: 6px;
    text-align: center;
    text-shadow: none;
    width: 19px;
    height: 18px;
    border-radius: 13.5px;
    background-color: rgba(255, 58, 48, 0.9);
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: -0.1px;
  }
}
</style>
<style lang="scss">
.jobSection{
  .myResume::after {
    width: 650%;
    right: 550%;
  }
}
</style>